.center-loader {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center; }

.center-placeholder {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%; }
  .center-placeholder__link {
    color: #005cbf;
    text-decoration: none; }
    .center-placeholder__link:hover {
      color: #0075f2; }
    .center-placeholder__link:active {
      color: #00438c; }

.separator {
  border: 0;
  height: 1px; }
  .separator--dark {
    background-image: -webkit-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -moz-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -ms-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -o-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0); }
  .separator--light {
    background-image: -webkit-linear-gradient(to left, #8c8b8b, #f0f0f0, #8c8b8b);
    background-image: -moz-linear-gradient(to left, #8c8b8b, #f0f0f0, #8c8b8b);
    background-image: -ms-linear-gradient(to left, #8c8b8b, #f0f0f0, #8c8b8b);
    background-image: -o-linear-gradient(to left, #8c8b8b, #f0f0f0, #8c8b8b);
    background-image: linear-gradient(to left, #8c8b8b, #f0f0f0, #8c8b8b); }

.button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  margin-bottom: 0.5em;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  .button--grey {
    background: #6c757d;
    border-color: #6c757d; }
    .button--grey:hover {
      background: #596167;
      transition: all 0.3s ease; }
    .button--grey:active {
      background: #313539;
      border-color: #6c757d; }
  .button--lignt-gray {
    background: #6c757d; }
    .button--lignt-gray:hover {
      background: #596167;
      transition: all 0.3s ease; }
    .button--lignt-gray:active {
      background: #313539;
      border-color: #6c757d; }
  .button--blue {
    background: #1b87c9; }
    .button--blue:hover {
      background: #166fa5;
      transition: all 0.3s ease; }
    .button--blue:active {
      background: #0c3c59;
      border-color: #6c757d; }

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3; }
  .close:hover {
    opacity: 1; }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }

.tab {
  display: flex; }
  .tab__header {
    flex: 1 1;
    padding: 1rem;
    justify-content: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .tab__header:not(.tab__header--active):hover {
      background-color: #d8d8d8;
      cursor: pointer; }
    .tab__header--active {
      background-color: white;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      cursor: default; }
  .tab__icon {
    align-items: center; }
    @media only screen and (max-width: 576px) {
      .tab__icon {
        display: flex;
        flex-direction: column; } }

.tabs {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%; }
  .tabs__header {
    display: flex;
    flex-flow: row;
    flex-shrink: 0;
    justify-content: space-around; }
  .tabs__active-content {
    display: grid;
    flex: 1 1 100%;
    background-color: white;
    width: 100%; }

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  width: 100%; }
  table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em; }
  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em; }
    table tr.tr--gray {
      background-color: rgba(0, 0, 0, 0.075); }
    table tr:hover {
      background-color: rgba(0, 0, 0, 0.05); }
  table th,
  table td {
    padding: 0.625em;
    text-align: left; }
  table th {
    font-size: 0.7em;
    letter-spacing: 0.1em;
    text-transform: uppercase; }
    table th.round-column {
      width: 60px; }
    table th.outcome-column {
      width: 80px; }
    table th.players-column {
      text-align: center; }

@media screen and (max-width: 768px) {
  table {
    border: 0; }
    table caption {
      font-size: 1.3em; }
    table thead {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em; }
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right; }
      table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase; }
      table td:last-child {
        border-bottom: 0; } }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000; }

.modal-main {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; }

.display-block {
  display: block; }

.display-none {
  display: none; }

body {
  padding-left: 240px; }

.menu {
  -webkit-overflow-scrolling: touch;
  background-color: #292c2f;
  height: 100vh;
  left: 0;
  outline: none;
  position: fixed;
  top: 0;
  width: 240px;
  z-index: 5; }
  .menu__content {
    overflow-x: visible;
    overflow-y: auto; }
  .menu .branding {
    color: #fff;
    font-size: 25px;
    line-height: 60px;
    padding-left: 20px;
    text-decoration: none; }
    .menu .branding:before {
      content: "MahjongTracker"; }
  .menu .avatar {
    padding-left: .5em;
    padding-right: .5em;
    padding-top: 2em;
    text-align: center; }
    .menu .avatar img {
      border-radius: 5px;
      overflow: hidden;
      width: 175px; }
    .menu .avatar hr {
      color: black; }
    .menu .avatar__text {
      color: #f5f5f5;
      font-weight: normal;
      margin-bottom: 0; }
  .menu ul {
    list-style: none;
    margin: 0;
    padding: .5em 0; }
    .menu ul a {
      background-position: left 15px center;
      background-repeat: no-repeat;
      background-size: auto 20px;
      cursor: pointer;
      font-size: .95em;
      font-weight: normal;
      padding: .5em 1em .5em 3em;
      transition: all .15s linear; }
      .menu ul a .icon-games,
      .menu ul a .icon-statistics,
      .menu ul a .icon-tournaments,
      .menu ul a .icon-settings {
        margin-right: 5px; }
      .menu ul a:hover {
        background-color: rgba(0, 0, 0, 0.1); }
      .menu ul a:focus {
        outline: none; }
  .menu .side-nav-link {
    background-color: #35393e;
    border-left: 2px solid #005cbf;
    color: #b3bcc5;
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 3;
    margin: 0 auto 5px auto;
    padding: 10px 0 10px 5px;
    text-align: left;
    text-decoration: none; }
  .menu .side-nav-link:hover {
    color: #80a7c5;
    background-color: #43474c; }
  .menu .side-nav-link.active {
    background-color: #eaeaea;
    color: #565d63;
    margin: 0; }

@media screen and (max-width: 992px) and (min-width: 576px) {
  body {
    padding-left: 90px; }
  .menu {
    width: 90px; }
    .menu__content {
      -webkit-overflow-scrolling: touch;
      height: calc(100% + 1px);
      overflow-x: visible;
      overflow-y: scroll; }
    .menu .branding {
      color: #fff;
      font-size: 25px;
      line-height: 60px;
      padding-left: 26px; }
      .menu .branding:before {
        content: "MT"; }
    .menu .avatar {
      padding: .5em;
      position: relative; }
      .menu .avatar img {
        width: 60px; }
      .menu .avatar__text {
        display: none; }
    .menu ul a {
      background-position: center center;
      background-size: 30px auto;
      height: 60px;
      position: relative; }
      .menu ul a .icon-games,
      .menu ul a .icon-statistics,
      .menu ul a .icon-tournaments,
      .menu ul a .icon-settings {
        font-size: 30px;
        margin-left: 20px;
        margin-right: 0;
        margin-top: 4px; }
      .menu ul a span {
        display: none; } }

@media screen and (max-width: 576px) {
  body {
    padding-left: 0; }
  .menu {
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0);
    transform: translate3d(-230px, 0, 0);
    transition: all .3s ease-in-out;
    width: 230px; }
    .menu__content {
      -webkit-overflow-scrolling: touch;
      height: 100%;
      overflow-x: visible;
      overflow-y: scroll; }
    .menu .avatar {
      padding: .5em;
      position: relative; }
      .menu .avatar img {
        width: 100px; }
      .menu .avatar__text {
        font-size: 1em;
        padding-top: 5px; }
    .menu .smartphone-menu-trigger {
      background: #1b87c9;
      color: #f5f5f5;
      height: 60px;
      left: 100%;
      position: absolute;
      width: 50px; }
      .menu .smartphone-menu-trigger:before {
        -webkit-font-smoothing: antialiased;
        content: "\f0c9";
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        font-size: 30px;
        font-style: normal;
        font-variant: normal;
        font-weight: 900;
        margin: 12px;
        text-rendering: auto; }
      .menu .smartphone-menu-trigger:after {
        top: 55%;
        transform: translate3d(-50%, -50%, 0); }
    .menu ul a {
      font-size: 1.2em;
      height: 100%;
      padding: 1em 1em 1em 3em; }
    .menu:focus {
      transform: translate3d(0, 0, 0); }
      .menu:focus .overlay {
        animation: fadein 2s;
        background: rgba(0, 0, 0, 0.3);
        height: 100%;
        margin-left: 230px;
        position: fixed;
        width: 150%;
        z-index: 1; }
  @keyframes fadein {
    from {
      opacity: 0; }
    to {
      opacity: 1; } }
      .menu:focus .smartphone-menu-trigger {
        pointer-events: none;
        z-index: 2; } }

.app-header {
  align-items: center;
  background-color: #292c2f;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: .5rem 1rem;
  position: relative; }
  @media screen and (min-width: 576px) {
    .app-header .page-name {
      color: #f5f5f5;
      max-width: 300px; } }
  @media screen and (max-width: 576px) {
    .app-header .page-name {
      color: #f5f5f5;
      flex: 1;
      margin-right: .5em;
      overflow: hidden;
      padding-left: 50px;
      text-overflow: ellipsis;
      white-space: nowrap; } }

.main-view {
  height: 100%; }
  .main-view__content {
    display: flex;
    width: 100%; }
  .main-view--column {
    display: grid;
    grid-template-rows: 60px auto;
    grid-template-columns: auto;
    grid-template-areas: "header" "main";
    height: 100%; }

.tournaments-list {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0 !important;
  color: #212529;
  padding: 0.5rem; }
  .tournaments-list__item {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 0.5rem;
    color: #212529;
    text-decoration: none;
    min-width: 450px;
    max-height: 200px;
    width: calc(50% - 1rem); }
    .tournaments-list__item:hover {
      background-color: #f2f2f2; }
  @media screen and (max-width: 1200px) {
    .tournaments-list__item {
      padding: 0.75rem 1.25rem;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      margin: 0.5rem;
      color: #212529;
      text-decoration: none;
      min-width: 200px;
      max-height: 200px;
      width: 100%; }
      .tournaments-list__item:hover {
        background-color: #f2f2f2; } }

.accordion {
  width: 100%; }
  .accordion input[name="section"] {
    display: none; }
  .accordion label {
    background: #fff;
    position: relative;
    display: flex;
    padding: 1em;
    font-size: 1em;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    color: #666;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87); }
    .accordion label:before {
      display: inline-block;
      font-family: "Font Awesome 5 Free";
      content: "\f105";
      /* "play" icon */
      color: #2d3236cc;
      width: 22px;
      float: left;
      padding-left: 2px;
      margin-right: 5px;
      font-weight: bold;
      font-size: 2em;
      line-height: 22px;
      -webkit-transition: 200ms ease-in-out;
      -moz-transition: 200ms ease-in-out;
      -o-transition: 200ms ease-in-out;
      transition: 200ms ease-in-out; }
    .accordion label:hover {
      color: #2980b9; }
  .accordion input:checked + label {
    border-bottom: 0;
    color: #2980b9; }
    .accordion input:checked + label:before {
      -ms-transform: rotate(90deg);
      /* IE 9 */
      -webkit-transform: rotate(90deg);
      /* Safari */
      transform: rotate(90deg);
      /* Standard syntax */ }
  .accordion .accordion__content {
    overflow: hidden;
    max-height: 0;
    position: relative;
    background-color: white;
    color: #eee;
    transition: all 0.3s cubic-bezier(0.865, 0.14, 0.095, 0.87);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .accordion .accordion__content:not(:last-of-type) {
      box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.25), inset 4px 0 0 0 #2980b9, inset 0 3px 6px rgba(0, 0, 0, 0.75); }
    .accordion .accordion__content .accordion__header {
      padding: 1em 0; }
    .accordion .accordion__content .accordion__body {
      font-size: .9em;
      line-height: 1.4em;
      padding: 0 0 1.5em; }

input[name="section"]:checked ~ .accordion__content {
  /* Get this as close to what height you expect */
  max-height: 150em; }

.tournament {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0.5rem 1rem 1rem; }
  .tournament .nav-link.undefined:hover {
    background-color: #dddddd;
    cursor: pointer; }
  .tournament .nav-link.undefined.active:hover {
    background-color: #ffffff;
    cursor: default; }
  .tournament .tabs-active-content {
    display: flex;
    flex-grow: 1;
    overflow-x: auto;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background-color: white; }

.games-list {
  background-color: #fff;
  margin: 1em;
  padding: 1em;
  width: 100%; }

.game-list-item {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #212529;
  display: flex;
  font-size: 1em;
  justify-content: space-between;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: .5rem;
  padding: 1rem;
  text-decoration: none; }
  .game-list-item:hover {
    background-color: #eaeaea; }
  .game-list-item__info {
    flex-grow: 1; }
    .game-list-item__info__header {
      font-weight: 500; }
  .game-list-item__round {
    align-self: center;
    font-weight: bolder;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    text-align: center; }

.rounds {
  display: flex;
  flex-flow: column;
  padding: 0.5rem 1rem 1rem;
  width: 100%; }
  .rounds .nav-link.undefined:hover {
    background-color: #dddddd;
    cursor: pointer; }
  .rounds .nav-link.undefined.active:hover {
    background-color: #ffffff;
    cursor: default; }
  .rounds .tabs-active-content {
    display: flex;
    flex-grow: 1;
    overflow-x: auto;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background-color: white; }

.achievements {
  margin: 1em;
  columns: 1; }
  @media only screen and (min-width: 992px) {
    .achievements {
      columns: 2;
      column-gap: 1em; } }
  @media only screen and (min-width: 1200px) {
    .achievements {
      columns: 3;
      column-gap: 1em; } }
  @media only screen and (min-width: 1900px) {
    .achievements {
      columns: 4;
      column-gap: 1em; } }

.achievement {
  display: inline-block;
  width: 100%;
  min-width: 295px;
  margin-bottom: 1em;
  text-align: center;
  color: #212529; }
  .achievement__body {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1.25rem;
    color: #212529;
    border-radius: 3px; }
  .achievement__title {
    font-size: 1.5em; }
  .achievement__subtitle {
    font-size: 1em;
    padding-bottom: 13px; }
  .achievement__text {
    margin: 10px; }

.ranking {
  overflow: auto; }

.rounds {
  padding: 1em; }
  .rounds__details {
    display: flex;
    font-size: 1.25em;
    font-weight: 300;
    line-height: 0.5em;
    margin: 0.25em;
    flex-flow: row; }
  .rounds__header-text {
    line-height: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    align-self: center; }
    .rounds__header-text--divider {
      width: 2px;
      margin: 0 6px;
      background: grey; }
    .rounds__header-text--bold {
      font-weight: bold; }

@media screen and (max-width: 768px) {
  .rounds {
    padding: 1em; }
    .rounds__details {
      display: flex;
      font-size: 1.25em;
      font-weight: 300;
      line-height: 1.25em;
      margin: 0.25em;
      flex-flow: row; }
    .rounds__header-text {
      line-height: 1.25em;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      align-self: center;
      min-width: 50px; }
      .rounds__header-text--divider {
        width: 2px;
        margin: 0 10px;
        background: grey; }
      .rounds__header-text--bold {
        font-weight: bold; } }

.game-statistics {
  padding: 1em;
  width: 100%; }
  .game-statistics__text {
    font-weight: bold; }

.timer__hanchan-list {
  display: flex;
  flex-direction: row;
  margin-left: auto; }
  .timer__hanchan-list--timer {
    margin-right: 5px; }

.full-screen-timer {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  align-items: center;
  justify-content: center; }
  .full-screen-timer__wrapper {
    display: flex;
    flex-direction: column; }
  .full-screen-timer__text {
    font-size: 5vw; }
  .full-screen-timer__time {
    font-size: 15vw;
    font-weight: 900; }

html,
body,
#app {
  box-sizing: border-box;
  height: 100%;
  font-family: "Roboto", sans-serif;
  margin: 0;
  background-color: #eaeaea; }

*,
*:before,
*:after {
  box-sizing: inherit; }
